.DatePick p {
  font-size: 16px;
}
.ShootDuration {
  max-width: 800px;
  margin: auto;
}
.StartTime {
  height: 42px;
  text-align: center;
  vertical-align: bottom;
  font-size: 16px;
  color: White;
}
.Slots span {
  font-size: 14px;
}
.Slots {
  max-width: 390px;
  margin: auto;
  text-align: center;
}

.SlotsInner {
  text-align: right;
  margin: auto;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}
.StartTimeInner {
  padding-top: 10px;
  vertical-align: bottom;
}

.Left {
  display: inline-block;
  width: 50%;
  vertical-align: top;
  min-width: 290px;
  background-image: url('https://public-assets-one.s3.amazonaws.com/tool_images/bg_black.jpg');
  background-repeat: repeat-x;
}

.Right {
  display: inline-block;
  width: 50%;
  vertical-align: top;
  background-image: url('https://public-assets-one.s3.amazonaws.com/tool_images/bg_black.jpg');
  background-repeat: repeat-x;
  min-width: 290px;
}
.Scheduling {
  display: flex;
}
.Appointment {
  margin-top: 20px;
  text-align: left;
}
.Appointment span {
  text-align: right;
}

.Guaranteed {
  border-bottom: 2px #41ad49 dashed;
}

.GuaranteedWrapper {
  text-align: right;
  padding-right: 25px;
}

@media only screen and (max-width: 600px) {
  .Scheduling {
    text-align: center;
  }
}
