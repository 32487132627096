.wrapper {
  min-height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
}
.footerDiv {
  margin-top: auto;
  padding-top: 40px;
}
.CommonContainer {
  padding-left: 20px;
  padding-right: 20px;
}
