.root {
  display: flex;
  align-items: center;
}

.item {
  margin-bottom: 8px;
  margin-left: 8px;
}

.item:nth-child(2) {
  margin-left: auto;
}
