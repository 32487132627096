.stripedRow {
  background-color: rgb(243, 243, 243);
}

.actions {
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
}

.action {
  cursor: pointer;
  appearance: none;
  border: none;
  border: 0;
  background-color: transparent;
  padding: 0;
}

.action + .action {
  margin-left: 8px;
}

.action:disabled {
  opacity: 0.35;
  cursor: not-allowed;
}

.notes {
  cursor: pointer;
  user-select: none;
  max-width: 100px;
  margin-top: 0;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.seeNotesButton {
  margin-left: 4px;
  font-size: 75%;
  display: inline-block;
  color: #888;
  cursor: pointer;
  appearance: none;
  border: none;
  border: 0;
  background-color: transparent;
  padding: 0;
}

.notesColumn {
  display: flex;
  align-items: center;
}
