.logo-logo.variation-primary {
  background-image: url(1x/logo-logo-primary-sm.png);
}

.logo-logo.size-sm {
  width: 100px;
  height: 19.19472405414787px;
}

.logo-logo.variation-primary {
  background-image: url(1x/logo-logo-primary-md.png);
}

.logo-logo.size-md {
  width: 150px;
  height: 28.792086081221797px;
}

.logo-logo.variation-primary {
  background-image: url(1x/logo-logo-primary-lg.png);
}

.logo-logo.size-lg {
  width: 200px;
  height: 38.389448108295724px;
}

.logo-logo.variation-secondary {
  background-image: url(1x/logo-logo-secondary-sm.png);
}

.logo-logo.variation-secondary {
  background-image: url(1x/logo-logo-secondary-md.png);
}

.logo-logo.variation-secondary {
  background-image: url(1x/logo-logo-secondary-lg.png);
}

.logo-logo.variation-black {
  background-image: url(1x/logo-logo-black-sm.png);
}

.logo-logo.variation-black {
  background-image: url(1x/logo-logo-black-md.png);
}

.logo-logo.variation-black {
  background-image: url(1x/logo-logo-black-lg.png);
}

.logo-logo.variation-white {
  background-image: url(1x/logo-logo-white-sm.png);
}

.logo-logo.variation-white {
  background-image: url(1x/logo-logo-white-md.png);
}

.logo-logo.variation-white {
  background-image: url(1x/logo-logo-white-lg.png);
}

@media only screen {
  .variation-primary {
    filter: saturate(0);
  }

  .variation-secondary {
    filter: saturate(0) brightness(0) invert(1);
  }

  .variation-black {
    filter: saturate(0);
  }

  .variation-white {
    filter: saturate(0) brightness(0) invert(1);
  }

  .logo-logo {
    background-image: url(../../svg/lemonone/logo.svg) !important;
  }
}
