.Date {
  display: inline-block;
  width: 50px;
  padding: 5px;
  margin-left: 5px;
}

.Guaranteed {
  border-bottom: 2px #41ad49 dashed;
}

.Selected {
  font-weight: bold;
  text-decoration: underline;
}
