.ShootAddress {
  max-width: 800px;
  text-align: center;
  margin: auto;
}

.ShootAddressText {
  margin-top: 1rem;
  margin-bottom: 20;
  text-align: left;
  font-size: 16px;
}
