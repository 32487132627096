.theme-blackish {
  --theme_alert_error_background_color: #f8d7da;
  --theme_alert_error_border_color: #f8d7da;
  --theme_alert_error_text_color: #000000;
  --theme_alert_primary_background_color: #f4fafa;
  --theme_alert_primary_border_color: #f4fafa;
  --theme_alert_primary_text_color: #000000;
  --theme_alert_secondary_background_color: #999999;
  --theme_alert_secondary_border_color: #999999;
  --theme_alert_secondary_text_color: #ffffff;
  --theme_alert_success_background_color: #f4fff2;
  --theme_alert_success_border_color: #f4fff2;
  --theme_alert_success_text_color: #000000;
  --theme_alert_warning_background_color: #fff3cd;
  --theme_alert_warning_border_color: #fff3cd;
  --theme_alert_warning_text_color: #000000;
  --theme_dimmed_text_color: #aaaaaa;
  --theme_disabled_opacity: 0.5;
  --theme_emails_background_color: #312f3e;
  --theme_emails_container_background_color: #fff;
  --theme_emails_container_padding: var(--theme_spacing);
  --theme_emails_container_text_color: #000;
  --theme_emails_separator: #000;
  --theme_emails_text_color: #fff;
  --theme_error_color: #842029;
  --theme_font_family: Helvetica, 'Droid Sans', 'Segoe UI', sans-serif;
  --theme_loading_text_color: black;
  --theme_primary_color_hover: #7f7f7f;
  --theme_primary_color: #000000;
  --theme_primary_contrast_color: white;
  --theme_primary_text_color: black;
  --theme_secondary_color_hover: #9a0000;
  --theme_secondary_color: #cd1719;
  --theme_secondary_contrast_color: white;
  --theme_spacing: 16px;
  --theme_success_color: #0f5132;
  --theme_warning_color: #664d03;
}
