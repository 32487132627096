.container {
  display: table;
  margin: auto;
}

.circle {
  width: 20px;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  min-height: 20px;
  max-height: 20px;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  display: table-cell;
  color: white;
  font-size: 9pt;
}

.circleBlack {
  background-color: black;
}

.circleGrey {
  background: #999999;
}

.circleChecked {
  background-image: url('check.svg');
  background-size: 10px 10px;
  background-repeat: no-repeat;
  background-position: center;
}

.statusLabel {
  display: table-cell;
  padding-left: 10px;
  vertical-align: middle;
  white-space: nowrap;
  padding-right: 5px;
  font-size: 9pt;
}

.labelWhite {
  color: white;
}

.labelGrey {
  color: #999999;
}

.number {
  font-size: 9pt;
  text-align: center;
  vertical-align: middle;
  color: white;
}

.stageNumberDivHidden {
  visibility: hidden;
}

@media only screen and (max-width: 650px) {
  .circle {
    margin: auto;
    display: block;
  }
  .container {
    display: block;
    text-align: center;
  }
  .statusLabel {
    display: block;
    vertical-align: middle;
    text-align: center;
    white-space: nowrap;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 10px;
  }
}
