.card {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.iconTime {
  width: 16pt;
  padding-right: 5px;
}

.media {
  height: 0;
  padding-top: 56.25%;
}
.cardContentRoot {
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.markdown {
  font-size: 14px !important;
}

.markDown li {
  line-height: 1.2;
  margin-bottom: 10px;
}
.markDown ul {
  padding-left: 30px;
  padding-bottom: 0px;
}

.productName {
  height: 90px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 18px;
  font-weight: bold;
}

.cardTime {
  padding-bottom: 10px;
  color: #999999;
  display: table;
  margin: auto;
  font-size: 14px;
}

.cellOne {
  display: table-cell;
  vertical-align: middle;
  height: 26px;
}
.cellTwo {
  display: table-cell;
}

.action {
  text-transform: none !important;
  border-radius: 4px !important;
  font-weight: bold;
  padding-right: 50px;
  padding-left: 50px;
  margin-bottom: 10px;
}
.wrapper {
  margin-bottom: 10px;
}
