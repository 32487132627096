.Actions a {
  color: black;
}
.ButtonStyle {
  min-width: 150px;
  border-radius: 4px;
  font-weight: bold;
}

.Tcd {
  font-size: 14px;
}

.Actions {
  margin-top: 20px;
}

.ButtonBlock {
  display: block;
  margin-top: 40px;
  text-align: center;
}
.ActionsBlock {
  display: block;
  width: 100%;
  text-align: center;
}
