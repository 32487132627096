.navContainter {
  display: table;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
}

.navElement {
  display: table-cell;
  width: 25%;
  text-align: right;
  background-image: url('arrow.svg');
  background-size: 40px;
  background-repeat: no-repeat;
  background-position: center right;
  padding-right: 45px;
  padding-left: ;
}

.navElement:last-of-type {
  background-image: none;
}

@media only screen and (max-width: 650px) {
  .navElement {
    padding-left: 0px;
    padding-right: 0px;
    background-position: right 6px;
  }

  .navContainter {
    display: table;
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 500px) {
  .navElement {
    background-size: 25px;
    background-image: url('arrow_short.svg');
  }
}
