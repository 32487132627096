.list {
  width: 450px;
  list-style: none;
  margin-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 16px;
}

.listItem + .listItem {
  margin-top: 8px;
}

.fieldName {
  font-weight: bold;
}

.fieldName + .fieldValue {
  margin-top: 8px;
}

.actions {
  display: flex;
}

.action + .action {
  margin-left: 8px;
}

.multilineText {
  margin-top: 0;
  margin-bottom: 0;
  white-space: pre-wrap;
  padding: 8px;
  background-color: whitesmoke;
  width: 100%;
  border-radius: 3px;
}
