.Hr {
  margin-bottom: 5px;
  margin-top: 20px;
  height: 1px;
  border: 0px;
  background-color: #ccc;
}

.Table {
  display: inline-block;
  vertical-align: top;
  margin-top: 10px;
}
.IconTime {
  width: 16px;
  margin-right: 10px;
  filter: brightness(0);
  margin-top: 2px;
}
