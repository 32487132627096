.contained {
  background-color: rgb(243, 243, 243);
}

.wrapper p {
  margin-top: 0;
  margin-bottom: 0;
  white-space: pre-wrap;
  width: 100%;
}

.table {
  border-collapse: collapse;
}

.cell {
  text-align: justify;
  vertical-align: top;
  line-height: 1;
  padding: 0;
}

.cell + .cell {
  padding-left: 6px;
}

.row + .row > .cell {
  padding-top: 6px;
}
