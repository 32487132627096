.SearchBar {
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 1rem;
  margin-bottom: 0;
  text-align: center;
  position: relative;
}
.Suggestions {
  max-width: 800px;
  text-align: center;
  margin: auto;
  padding-top: 0px;
}

.hint {
  font-style: italic;
  font-size: 85%;
  display: flex;
  align-items: center;
  margin: 0 auto;
  max-width: 800px;
  height: 45px;
  background: #fff;
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
}

.hintTitle + .hintGroup {
  margin-left: 4px;
}

.hintGroup {
  padding: 2px 6px;
  background: #eee;
  border-radius: 6px;
}

.hintGroup + .hintGroup {
  margin-left: 4px;
}
