.wrapper {
  display: flex;
  align-items: center;
}

.content {
  display: flex;
}

.cell {
  user-select: none;
  cursor: pointer;
}

.direction {
  color: transparent;
  margin-left: 4px;
  font-size: 10px;
}

.sorted {
  color: inherit;
}
